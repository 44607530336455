<template>
  <div class="flex">
    <div
      v-if="vendor"
      class="pin_element__vendor"
    >
      <img
        v-if="vendorIcon"
        :src="vendorIcon"
        :alt="vendor"
      />
    </div>

    <div
      class="pin_element__delete_btn"
      @click.stop="handleDeleteBtn"
    >
      <Icon icon="pins/pin_close" />
    </div>

    <div class="pin_element__favorite">
      <Icon
        v-if="isFavorite"
        icon="pins/pin_favorite_fill"
      />
    </div>

    <div class="pin_element__lock">
      <Icon
        v-if="isLocked"
        icon="pins/pin_lock"
      />
    </div>
  </div>
</template>

<script setup>
// packages
import { computed, onMounted } from 'vue';
// stores, utils
import { useUserStore } from '@/store/user';
import { useSettingsStore } from '@/store/settings';
import { useFavoritesListStore } from '@/store/favoritesStore';
// components
import Icon from '@/components/common/icons/icon.vue';

// props & emits
const props = defineProps({
  content: {
    type: Object,
    required: true,
    default: () => ({}),
  },
  channel: {
    type: [Object, String],
    required: true,
  },
});

const favoritesStore = useFavoritesListStore();
if (process.client) {
  onMounted(async () => {
    await favoritesStore.fetchFavorites();
  });
}

// reactive
const vendor = computed(() => {
  if (props.content.pause_type !== 'film') return props.channel.has_record ? 'archive' : '';
  return typeof props.content.vendor !== 'undefined' ? props.content.vendor : '';
});

const { settings } = useSettingsStore();
const vendorIcon = computed(() => {
  if (vendor.value === 'archive') return `/icons/daysLeft/days${daysLeft(props.channel)}.svg`;

  const iconPath = settings?.CLIENT_SETTINGS?.mainmenu_icons_path;
  if (!iconPath) return false;
  return `${iconPath}/web_client/img/pin_${vendor.value}.svg`;
});

const programInFavorites = computed(() =>
  favoritesStore.favoritesList.some(favorites => favorites.channel_id === props.channel.id)
);
const vodIdInFavorites = computed(() =>
  favoritesStore.favoritesList.some(favorites => favorites.film_id === props.content.film_id)
);
const isFavorite = computed(() => programInFavorites.value || vodIdInFavorites.value);

const { user } = useUserStore();
const isLocked = computed(() => {
  if (props.content.pause_type !== 'film') return props.channel.locked;
  return user.tariff_options.indexOf(props.content.vendor) === -1;
});

// methods
const emit = defineEmits(['deleteFromPause']);
const handleDeleteBtn = async () => emit('deleteFromPause', props.content);

function daysLeft(channel) {
  if (channel.record_depth) {
    const now = new Date().getTime() / 1000;
    const daysLeft = (channel.record_depth - (now - channel.program_begin_time) / 60) / 24;
    return Math.ceil(daysLeft);
  }
  return 1;
}
</script>
