<template>
  <div class="program_card">
    <a
      :href="generateUrl(content)"
      @click.prevent="onClickProgram(content)"
    >
      <div class="program_card__icon">
        <img
          ref="defaultImage"
          :src="content.images.poster_604_348"
          @error="putErrorImage"
          :alt="content.program_name || content?.serial_title || content?.title || content?.name"
        >
        <PinElement
          v-if="showPins"
          class="program_card__pin_element"
          :content="content"
          :channel="channel"
          @delete-from-pause="deleteFromPause(content)"
        />
      </div>
    </a>

    <div class="programProgressBack">
      <div
        class="programProgressFront"
        :style="{ width: progressPercent + '%' }"
      />
    </div>

    <div class="program_card__title">
      {{ title }}
    </div>
    <div class="program_card__program">
      {{ program }}
    </div>
  </div>
</template>

<script setup>
// packages
import { computed } from 'vue';
import { format } from 'date-fns';
import ru from 'date-fns/locale/ru/index.js';
// stores, utils
import { getDefaultImage } from '@/utils/ui/getDefaultImage';
import { useChannelsListStore } from '@/store/channelsList';
// components
import PinElement from '/components/ui/Pauses/PinElement.vue';

// props & emits
const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
  // for VOD:
  isVodSerial: {
    type: Boolean,
    required: false,
  },
  // for TV:
  isTvSerial: {
    type: Boolean,
    required: false,
  },
  isEpisode: {
    type: Boolean,
    required: false,
  },
  showPins: {
    type: Boolean,
    required: false,
  },
});

// reactive
const defaultImage = getDefaultImage('poster_604_348');
const isFilm = computed(() => props.content.pause_type === 'film');
const serialTitle = computed(() =>'serial_title' in props.content);
const progressPercent = shallowRef(0);

const program = computed(() => {
  // компонент "PausesList":
  if (!isFilm.value && props.content.name) return props.content.name;

  // компонент "PosterDetail", TV:
  if (props.isEpisode) return 'От ' + format(props.content.time_start * 1000, 'dd.MM HH:mm', { locale: ru });

  // компонент "PosterDetail", VOD:
  if (props.isVodSerial) return props.content.title;

  // компонент "PosterDetail", TV:
  if (props.isTvSerial) return props.content.subtitle;

  return serialTitle.value ? props.content.name : '\u00a0';
});

async function getProgressPercent() {
  if (props.content && props.content.pause_time) {
    if (!isFilm.value) {
      progressPercent.value = Math.round(100 * (parseInt(props.content.pause_time) - props.content.time_start) / (props.content.time_end - props.content.time_start));
      return;
    }
    if (props.content.duration) return progressPercent.value = Math.round(100 * parseInt(props.content.pause_time) / props.content.duration);
  }
  return progressPercent.value = 0;
}

watchEffect(async () => await getProgressPercent());

const channelStore = useChannelsListStore();
const channel = computed(() => {
  if (props.content.pause_type !== 'program') return '';
  return channelStore.currentChannel(props.content.channel_id);
});

const title = computed(() => {
  // компонент "SearchPage"
  if (props.content.program_name) return props.content.program_name;

  // компонент "PosterDetail", VOD:
  if (props.isVodSerial && props.content.serial_title) return props.content.serial_title;

  // компонент "PosterDetail", TV:
  if (props.isTvSerial && props.content.title) return props.content.title;

  // компонент "PausesList":
  return !isFilm.value
    ? channel.value.name ? `Канал "${channel.value.name}"` : ''
    : serialTitle.value
    ? props.content.serial_title
    : props.content.name;
});

// methods
function generateUrl(content) {
  if (content.channel_id) {
    const time = content.pause_time || content.time_start || content.program_begin_time;
    return `/channel/${content.channel_id}/${time}`;
  }
  if (content.film_id || content.id) return `/vod/${content.film_id || content.id}`;
}

function onClickProgram(content) {
  if (content.channel_id) {
    const time = content.pause_time || content.time_start || content.program_begin_time;
    navigateTo({
      name: 'channel-arg',
      params: { arg: [content.channel_id, time] },
    });
  }

  if (content.film_id || content.id) {
    navigateTo({ name: 'vod-name', params: { name: content.film_id || content.id } });
  }
}

function putErrorImage(event) {
  event.target.src = defaultImage;
}

const pausedStore = usePausedListStore();
async function deleteFromPause(content) {
  if (content.pause_type === 'film') {
    await pausedStore.deleteFromPause({ film_id: content.film_id, pause_time: content.pause_time });
  }

  if (content.pause_type === 'program') {
    await pausedStore.deleteFromPause({ channel_id: content.channel_id, pause_time: content.pause_time });
  }
}
</script>
